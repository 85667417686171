.header {
    position: relative;
    width: 100%;
    height: 75vh;
}

.headerImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: contrast(100%) grayscale(50%) brightness(75%);
}

.headerContTitulo {
    position: absolute;
    width: 100%;
    height: calc(100% - var(--alto-navbar));
    bottom: 0;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.50rem;
    margin-top: 10rem;
    text-align: center;
    justify-content: flex-end;
    padding-bottom: 4rem;
    padding-right: 1rem;
    padding-left: 1rem;
}

.headerTitulo {
    font-size: 3.5rem;
    font-weight: 500;
}

.lineaSeparadora {
    margin-bottom: 2rem;
    max-width: 80%;
    filter: brightness(75%);
}

.headerSubTitulo {
    letter-spacing: 0.35rem;
    font-weight: 400;
    word-spacing: 0.50rem;
    animation-name: delaySubtitulo;
    animation-duration: 1.25s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    animation-delay: 0.75s;
}

@keyframes delaySubtitulo {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}