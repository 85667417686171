.footerCont {
    width: 100%;
}

.footer {
    display: grid;
    grid-template-columns: 50% 50%;
    height: fit-content;        
    background-color: #252B29;
    width: 100%;
    padding: 1rem;
}

.footerMapa {
    border-style: none;
    margin: 0;
    height: 14rem;
    width: 25rem;
    max-width: 40vw;
}

.footerContIz, .footerContDer {
    width: 100%;
    height: 100%;
}

.footerContDer {
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 1.5rem;
}

.footerContDer p{
    color: white;
}

.footerContDer img {
    height: 2rem;
    margin-right: 0.5rem;
}

.footerContDer img:hover {
    cursor: pointer;
}

.footerImgCont {
    width: 100%;
    justify-content: flex-start;
    padding: 1.5rem;
    padding-left: 0;
}

.footerContIzq {
    width: 100%;
    align-items: flex-start;
    justify-content: flex-end;
    padding-right: 1.5rem;
}

.footerTextoContato {
    font-size: 1rem;
    font-weight: 200;
    line-height: 2;
    color: #cacaca;
}

.footerTituloContato {
    font-size: 1.50rem;
    margin-bottom: 1.5rem;
}

.footerDato {
    margin-left: 1rem;
}

.footerTituloDato {
    border-bottom: 1px solid #3C3C3C;
}

@media only screen and (orientation: portrait) {
    .footer {
        display: flex;
        flex-direction: column;
        flex-direction: column-reverse;
    }

    .footerContIzq {
        justify-content: center;
        width: 100%;
        padding: 1rem;
        margin: 0;
    }

    .footerMapa {
        max-width: unset;
        width: 100%;
    }
}