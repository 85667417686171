.galeria {
    width: 100%;
}

.contImgGaleria {
    position: relative;
    width: 15rem;
    aspect-ratio: 3/4;
    margin: 0.1rem;
}

.imgGaleria {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.contImgGaleria:hover {
    transition-duration: 0.25s;
    transition-timing-function: ease-out;
    cursor: pointer;
    filter: grayscale(100%);
    perspective: 3.5rem;
}

.logoImagenes {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    height: 4rem;
    z-index: 100;
    filter: invert(100%);
    z-index: 100;
    animation-name: logogiro;
    animation-duration: 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    pointer-events: none;
}

@keyframes logogiro {
    0% {
        transform: rotate3d(0, 1, 0, 0turn)
    }
    100% {
        transform: rotate3d(0, 1, 0, 1turn)
    }
}

.contImgZoom {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-web-2);
    z-index: 200;
    animation-name: imgZoom;
    animation-duration: 0.25s;
    animation-timing-function: ease-out;
}

@keyframes imgZoom {
    0% {
        opacity: 0%;
    }
    100% {
        opacity: 100%;
    }
}

.imgZoom {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.zoomGaleriaClose {
    position: absolute;
    height: 3rem;
    filter: invert(100%);
    top: 0.5rem;
    right: 0.5rem;
    cursor: pointer;
}

@media only screen and (orientation: portrait) {

    .contImgGaleria {
        width: 45vw;
    }    
}